export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  MASTER = 'MASTER',
  customercare = 'customer-care',
  supervisore = 'supervisore',
  autista = 'autista',
  hostess = 'hostess',
  rivenditore = 'rivenditore',
}

export enum ERoleActions {
  VIEW_LOG = 'VIEW_LOG',
  VIEW_COLUMN = 'VIEW_COLUMN',
}

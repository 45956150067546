import { SuperModel } from './super-model.model';

const ProductType = {
  base: 'Adulto',
  reduced: 'Ragazzo',
  infant: 'Bambino',
  luggageTicket: 'Bagaglio aggiuntivo',
  secondLuggageTicket: 'Bagaglio speciale ',
  secondluggage: 'Bagaglio speciale',
  luggage: 'Bagaglio aggiuntivo',
  singleseat: 'Posto singolo',
  seatchoise: 'Scelta posto',
  bike: 'Bici',
};

export class TicketReport extends SuperModel {
  amount: number;
  arrivalCode: string;
  arrivalDate: string;
  arrivalDesc: string;
  customerEmail: string;
  departureCode: string;
  departureDate: string;
  departureDesc: string;
  firstName: string;
  lastName: string;
  operatorUsername: string;
  orderNumber: string;
  pnr: string;
  productType: string;
  quantity: number;
  rideDesc: string;
  rideId: string;
  routeDesc: string;
  routeId: string;
  timeStamp: Date;
  validationDate: Date | null;
  validationEmail: string;
  validationUsername: string;
  orderId: string;
  ticketStatus?: string;
  isPaidWithVoucher?: boolean;
  voucher?: number;
  salesChannel?: string;

  constructor(data: Partial<TicketReport> = {}) {
    super(data);
    this.timeStamp = new Date(data.timeStamp);
    this.validationDate = new Date(data.validationDate);
  }

  get productTypeDesc() {
    return ProductType[this.productType] || this.productType;
  }

  get serialised() {
    return {
      id: this.id,
      amount: this.amount,
      arrivalCode: this.arrivalCode,
      arrivalDate: this.arrivalDate,
      arrivalDesc: this.arrivalDesc,
      customerEmail: this.customerEmail,
      departureCode: this.departureCode,
      departureDate: this.departureDate,
      departureDesc: this.departureDesc,
      firstName: this.firstName,
      lastName: this.lastName,
      operatorUsername: this.operatorUsername,
      orderNumber: this.orderNumber,
      pnr: this.pnr,
      productType: this.productType,
      quantity: this.quantity,
      rideDesc: this.rideDesc,
      rideId: this.rideId,
      routeDesc: this.routeDesc,
      validationEmail: this.validationEmail,
      validationUsername: this.validationUsername,
      timeStamp: this.timeStamp.toISOString(),
      validationDate: this.validationDate?.toISOString(),
      orderId: this.orderId,
      ticketStatus: this.ticketStatus,
      isPaidWithVoucher: this.isPaidWithVoucher,
      voucher: this.voucher,
      salesChannel: this.salesChannel,
    };
  }
}
